<template>
  <div class="preview">
    <main>
      <!-- word -->
      <div v-if="show_word" v-html="word_html"></div>
      <!-- video -->
      <div v-if="show_video" v-html="video_html"></div>
      <!-- img -->
      <img v-if="show_img" :src="img_src" alt="" />
      <!-- Excel -->
      <el-table v-if="show_excel" :data="excelData" style="width: 100%">
        <el-table-column
          v-for="(value, key, index) in excelData[2]"
          :key="index"
          :prop="key"
          :label="key"
        >
        </el-table-column>
      </el-table>
      <!-- ppt -->
      <div class="ppt_box" v-if="show_ppt">
        <iframe
          id="iframe1"
          width="100%"
          height="600px"
          frameborder="no"
          border="0"
          marginwidth="0"
          marginheight="0"
          scrolling="no"
          allowtransparency="yes"
          :src="`http://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
            ppt_path
          )}`"
        ></iframe>
      </div>
      <!-- pdf -->
      <div class="pdf_box" v-show="show_pdf">
        <iframe
          ref="mainiframe"
          :src="pdf_path"
          style="height: 600px; width: 100%"
        ></iframe>
      </div>
      <audio controls v-if="show_audio" id="audio">
        <source :src="audio_path" />
        您的浏览器不支持 audio 元素。
      </audio>
    </main>
  </div>
</template>

<script>
import mammoth from "mammoth";
import * as XLSX from "xlsx/xlsx.mjs";
export default {
  data() {
    return {
      // word
      word_html: "",
      show_word: false,
      // video
      video_html: "",
      show_video: false,
      // img
      img_src: "",
      show_img: false,
      // Excel
      show_excel: false,
      excelData: [],
      workbook: {},
      // ppt
      show_ppt: false,
      ppt_path: "",
      // pdf
      show_pdf: false,
      pdf_path: "",
      // 音频
      show_audio: false,
      audio_path: "",
    };
  },
  props: ["response_info", "dialogpreview"],
  created() {
    this.file_previwe(this.response_info);
  },
  mounted() {
    this.file_previwe(this.response_info);
  },
  watch: {
    response_info(value) {
      this.file_previwe(value);
    },
    dialogpreview(value) {
      if (!value) {
        if (this.response_info.file.type === "video") {
          document.querySelector("#video").remove();
        }
        if (this.response_info.file.type === "audio") {
          let audio = document.querySelector("#audio");
          audio.pause();
        }
      }
    },
  },
  methods: {
    // word
    async readWordFromRemoteFile(url) {
      let that = this;
      const res = await this.axios.get(url, { responseType: "arraybuffer" });
      mammoth
        .convertToHtml({ arrayBuffer: new Uint8Array(res) })
        .then(function (resultObject) {
          that.$nextTick(() => {
            that.word_html = resultObject.value;
          });
        });
    },
    // Excel
    async redExcelFromRemoteFile(url) {
      let that = this;
      const res = await this.axios.get(url, { responseType: "arraybuffer" });
      let data = new Uint8Array(res);
      let workbook = XLSX.read(data, { type: "array" });
      let sheetNames = workbook.SheetNames; // 工作表名称集合
      that.workbook = workbook;
      that.getTable(sheetNames[0]);
    },
    // pdf
    async redPdfFromRemoteFile(url) {
      const res = await this.axios.get(url, { responseType: "arraybuffer" });
      this.pdf_path = window.URL.createObjectURL(
        new Blob([res], { type: "application/pdf" })
      );
    },
    getTable(sheetName) {
      let worksheet = this.workbook.Sheets[sheetName];
      this.excelData = XLSX.utils.sheet_to_json(worksheet);
    },
    file_previwe(value) {
      this.close_box();
      let type = value.file.type;
      let mime = value.file.mime;
      console.log(type);
      if (type === "doc" || type === "docx") {
        this.show_word = true;
        this.readWordFromRemoteFile(value.file.path);
      }
      if (type === "video") {
        this.show_video = true;
        this.video_html = `<video id='video'  width="100%" height="100%" controls>
                              <source src="${value.file.path}">
                            </video>`;
      }
      if (type === "image") {
        this.show_img = true;
        this.img_src = value.file.path;
      }
      if (type === "xlsx" || type === "xls") {
        this.show_excel = true;
        this.redExcelFromRemoteFile(value.file.path);
      }
      if (type === "pptx") {
        this.show_ppt = true;
        this.ppt_path = value.file.path;
      }
      if (type === "pdf" || mime === "pdf") {
        this.show_pdf = true;
        this.redPdfFromRemoteFile(value.file.path);
      }
      if (type === "audio") {
        this.show_audio = true;
        this.audio_path = value.file.path;
      }
    },
    close_box() {
      this.show_word = false;
      this.show_video = false;
      this.show_img = false;
      this.show_excel = false;
      this.show_ppt = false;
      this.show_pdf = false;
      this.show_audio = false;
    },
  },
};
</script>

<style lang="less" scoped></style>
